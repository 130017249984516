<template>
  <a-modal v-model="modalVisiable" :title="$t(`提交审核`)" @ok="handleOk">
    <a-form-model v-bind="formItemLayout">
      <a-form-model-item :label="$t(`开始时间`)" required>
        <date-range-picker
          id="onSaleTime"
          ref="onSaleTime"
          :needhms="true"
          :singleDatePicker="true"
          @get_date="getOnSaleTime"
        ></date-range-picker>
      </a-form-model-item>
      <a-form-model-item :label="$t(`结束时间`)">
        <date-range-picker
          id="offSaleTime"
          ref="offSaleTime"
          :needhms="true"
          :singleDatePicker="true"
          @get_date="getOffSaleTime"
        ></date-range-picker>
      </a-form-model-item>
      <!-- <a-form-model-item label="Special Price">
        <a-input v-model="modalData.specialPrice"></a-input>
      </a-form-model-item>
      <a-form-model-item label="促销日期">
        <date-range-picker
          id="promotionDate"
          :needhms="true"
          @get_date="getPromotionDate"
        ></date-range-picker>
      </a-form-model-item> -->
    </a-form-model>
  </a-modal>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'
import dateRangePicker from '@component/dateRangePicker'
export default {
  //本地分类列表
  name: 'commitLazadaGoods',
  props: ['visible', 'itemId'],
  data() {
    return {
      modalData: {
        onSaleTime: '',
        offSaleTime: '',
        specialFromTime: '',
        specialToTime: '',
        specialPrice: '',
      },
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      },
    }
  },
  components: { dateRangePicker },
  computed: {
    modalVisiable: {
      get() {
        this.visible && this.clearModalForm()
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  mounted() {},
  methods: {
    clearModalForm() {
      this.modalData = {
        onSaleTime: '',
        offSaleTime: '',
        specialFromTime: '',
        specialToTime: '',
        specialPrice: '',
      }
    },
    getOnSaleTime(date) {
      this.modalData.onSaleTime = date
    },
    getOffSaleTime(date) {
      this.modalData.offSaleTime = date
    },
    getPromotionDate(date) {
      this.modalData.specialFromTime = date.start_time
      this.modalData.specialToTime = date.end_time
    },
    handleOk() {
      let shopCode = localStorage.getItem('shopCode')
      if (!this.modalData.onSaleTime) {
        this.$warning(this.$t(`开始时间必填`))
        return
      }
      return new Promise((reslove) => {
        http({
          instance: this,
          type: 'post',
          url: api.commitLazadaGoods,
          hasLoading: true,
          data: {
            itemIdList: [this.itemId],
            ...this.modalData,
            shopCode: shopCode,
          },
          success: () => {
            this.modalVisiable = false
            this.$success(this.$t(`提交审核`) + this.$t(`已完成`))
            this.$emit('handleOk')
            reslove()
          },
        })
      })
    },
  },
  watch: {
    modalVisiable(newVal) {
      if (newVal) {
        this.$refs.onSaleTime.clear()
        this.$refs.offSaleTime.clear()
        this.modalData = {
          onSaleTime: '',
          offSaleTime: '',
          specialFromTime: '',
          specialToTime: '',
          specialPrice: '',
        }
      }
    },
  },
}
</script>
