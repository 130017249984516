<template>
  <a-select
    allowClear
    :placeholder="selfPlaceholder"
    style="width: 100%"
    @change="selectShop"
    :disabled="disabled"
    v-model="shopCode"
  >
    <a-select-option v-for="item in shopList" :value="item.shopCode" :key="item.shopCode">
      {{ item.platformLocale + '-' + item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['code', 'placeholder', 'disabled', 'platform', 'init', 'allShop'],
  data() {
    return {
      shopCode: undefined,
      shopList: [],
    }
  },
  computed: {
    ...mapGetters(['currentShop', 'partyId']),
    selfPlaceholder() {
      return this.placeholder || this.$t(`请选择店铺`)
    },
  },
  methods: {
    ...mapActions(['getShopInfoList']),
    fetchShopList() {
      this.getShopInfoList({
        params: { platform: this.platform },
        callback: (list) => {
          if (!this.allShop) {
            list = list.filter((item) => item.partyId == this.partyId)
          }
          this.shopList = list.map((item) => ({
            code: item.shopCode,
            ...item,
          }))
          if (this.code) {
            this.shopCode = this.code
          } else if (this.init) {
            this.$emit('selected', {
              data: this.shopList[0],
            })
          }
        },
      })
    },
    selectShop(value) {
      this.$emit('update:code', value)
      if (value) {
        let data = this.shopList.find((item) => item.shopCode === value) || {}
        this.$emit('selected', data)
      } else {
        this.$emit('selected', {})
      }
    },
    reset() {
      this.shopCode = undefined
    },
  },
  watch: {
    platform: {
      handler() {
        this.shopCode = undefined
        this.$nextTick(() => {
          this.fetchShopList()
        })
      },
      immediate: true,
    },
    code: {
      handler(val) {
        this.$nextTick(() => {
          this.shopCode = val
          this.selectShop(val)
        })
      },
      immediate: true,
    },
  },
}
</script>
