<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content" style="margin: 0 10px">
      <section class="clearfix" style="margin-bottom: 1%">
        <breadcrumb></breadcrumb>
        <!-- <a-button type="primary" @click="platformGoodsSync">
          <i
            class="icon iconfont icon-synchronize"
            style="margin-right: 5px"
          ></i
          >{{ $t(`同步平台店铺商品`) }}
        </a-button> -->
        <a-button type="primary" v-if="country == 'TH'" @click="goToAddProduct(true)">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新建店铺商品') }}
        </a-button>
        <a-button type="primary" v-if="country == 'TH'" @click="openStoreModal">
          {{ $t('更新库存') }}
        </a-button>
      </section>
      <a-tabs
        type="card"
        :default-active-key="platformList[0]"
        @change="changeTabs"
        :tabBarGutter="tabGutter"
      >
        <a-tab-pane
          :tab="platformMapping[item]"
          v-for="item in platformList"
          :key="item"
        ></a-tab-pane>
      </a-tabs>

      <a-row>
        <a-col :span="5" style="margin-right: 15px">
          <SelectShop
            :code.sync="formData.shopCode"
            @selected="selectedShop"
            :platform="formData.platform"
          />
        </a-col>
        <a-col :span="5" style="margin-right: 15px">
          <a-input
            type="text"
            v-model.trim="formData.nameOrSellerSku"
            :placeholder="
              $t(`请输入`) + $t(`商家编码`) + '/' + $t(`商品名称`) + '/' + $t(`平台商家编码`)
            "
          />
        </a-col>
        <a-col :span="5" style="margin-right: 15px">
          <categoryList
            :canNotAdd="true"
            @handleCategoryChange="handleCategoryChange"
          ></categoryList>
        </a-col>
        <a-button
          type="primary"
          @click="queryPlatformSkuPageList(1)"
          v-if="priv('f_platform_goods_list')"
        >
          <i class="icon iconfont icon-search" style="margin-right: 5px"></i>
          {{ $t(`查询`) }}
        </a-button>
        <a-button @click="reset">
          <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
          {{ $t(`重置`) }}
        </a-button>
        <a-button @click="onExport" style="margin-left: 15px">
          <i class="icon iconfont icon-upload" style="margin-right: 5px"></i>
          {{ $t(`导出`) }}
        </a-button>
      </a-row>

      <!-- <a-input
                type="text"
                v-model.trim="formData.categoryName"
                class="form-control input-sm"
                :placeholder="$t(`商品类目`)"
              /> -->

      <div class="table-content">
        <div class="table-header">
          <div class="row status-list-wrap">
            <input type="hidden" v-model="formData.omsStatus" />
            <a-tabs default-active-key="" @change="setSearchStatus" :tabBarGutter="tabGutter">
              <a-tab-pane v-for="item in tabList" :key="item.code">
                <template slot="tab">
                  <a-popover v-if="item.tips">
                    <template slot="content">
                      <div v-html="item.tips" />
                    </template>
                    <div>
                      {{ item.name }}
                      <i class="fa-regular fa-circle-question"></i>
                    </div>
                  </a-popover>
                  <span v-else>{{ item.name }}</span>
                </template>
              </a-tab-pane>
            </a-tabs>
            <!-- <ul class="status-list-bottom clearfix">
              <li
                v-for="(status, index) in statusListBottom"
                @click="setSearchStatus(status.status, $event)"
                :key="index"
                :class="{ active: status.status === formData.statusType }"
              >
                <a-popover v-if="status.tips">
                  <template slot="content">
                    <div>{{ status.tips }}</div>
                  </template>
                  <div>
                    {{ status.statusName }}
                    <i class="fa-regular fa-circle-question"></i>
                  </div>
                </a-popover>
                <span v-else>{{ status.statusName }}</span>
              </li>
            </ul> -->
          </div>
          <ul class="action-list" v-show="tableData.length">
            <li>
              <!-- 批量删除 -->
              <!-- <a-button class="bgcBtn" @click="batchDelete">
                {{ $t(`批量删除`) }}
              </a-button> -->
            </li>
            <li>
              <!-- 批量上架 -->
              <a-button class="bgcBtn" @click="batchOn" v-if="priv('f_platform_goods_on_sale')">
                {{ $t(`批量上架`) }}
              </a-button>
            </li>
            <li>
              <!-- 批量下架 -->
              <a-button class="bgcBtn" @click="batchOff" v-if="priv('f_platform_goods_off_sale')">
                {{ $t(`批量下架`) }}
              </a-button>
            </li>
            <li>
              <!-- 批量开启同步 -->
              <a-button
                class="bgcBtn"
                @click="batchOpenSyncStock"
                v-if="priv('f_platform_goods_open_sync_stock')"
              >
                {{ $t(`批量开启同步`) }}
              </a-button>
            </li>
            <li>
              <!-- 批量关闭同步 -->
              <a-button
                class="bgcBtn"
                @click="batchCloseSyncStock"
                v-if="priv('f_platform_goods_close_sync_stock')"
              >
                {{ $t(`批量关闭同步`) }}
              </a-button>
            </li>
            <li>
              <!-- 批量铺货 -->
              <a-button
                class="bgcBtn"
                @click="openCopyPlatformGoods"
                v-if="priv('f_platform_goods_batch_replicate')"
              >
                {{ $t(`批量铺货`) }}
              </a-button>
            </li>
          </ul>
        </div>
        <div class="table-body">
          <a-table
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :columns="tableColumns"
            :data-source="tableData"
            :pagination="false"
            :scroll="{ x: 2000, y: tableHeight }"
            bordered
            :rowKey="
              (record, index) => {
                return record.skuId
              }
            "
          >
            <span slot="sellerSku" slot-scope="text, scope">
              <a-popover placement="top" v-if="scope.mainUrl">
                <template slot="content">
                  <img :src="scope.mainUrl" width="200" alt="" />
                </template>
                <a :href="scope.url" target="_blank">
                  {{ text }}
                </a>
              </a-popover>
              <a :href="scope.url" target="_blank" v-else>
                {{ text }}
              </a>
            </span>
            <span slot="image" slot-scope="text, scope">
              <router-link
                :to="{
                  name: 'platformGoodsImage',
                  query: { id: scope.sellerSku },
                }"
              >
                {{ $t(`查看`) }}
              </router-link>
            </span>
            <div slot="quantity" slot-scope="text, record">
              <div class="text-info" @click="showSyncList(record)">
                {{ text }}
                <!-- {{ $t('查看库存同步记录') }} -->
              </div>
            </div>
            <span slot="omsStatus" slot-scope="text">
              {{ omsStatusMapping[text] }}
            </span>
            <div slot="action" slot-scope="text, scope">
              <a-switch
                :checked="scope.syncStock"
                @change="onSwitchChange(!scope.syncStock, [scope.skuId], scope.platform)"
              />
            </div>
          </a-table>
        </div>
        <div class="table-footer">
          <pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          ></pagination>
        </div>
      </div>
    </section>
    <commitLazadaGoods
      :visible.sync="visible"
      :itemId="modalItem.itemId"
      @handleOk="handleOk"
    ></commitLazadaGoods>
    <a-modal
      v-model="copyVisible"
      width="440px"
      :title="$t('铺货')"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="setGoodsPublish"
      @cancel="closeGoodsPublish"
      :bodyStyle="bodyStyle"
    >
      <a-form-model>
        <a-form-model-item :label="$t(`店铺`)">
          <SelectMutiShop
            :allShop="allShop"
            :platform="formData.platform"
            :shopCodeList.sync="copyModalData.destShopCodeList"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="updateInventoryVisible"
      :title="$t('更新库存')"
      :confirmLoading="confirmLoading"
      @ok="goToSyncStore"
      @cancel="updateInventoryVisible = false"
      :bodyStyle="bodyStyle"
    >
      <a-form-model>
        <a-form-model-item :label="$t(`店铺`)">
          <SelectShop
            @selected="selectedSyncShop"
            :platform="formData.platform"
            style="width: 400px"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="inventorySyncRecordVisible"
      :title="$t('menu.inventorySyncRecord')"
      width="1000px"
      :footer="null"
    >
      <div class="table-body">
        <a-table
          :loading="tableLoading"
          :columns="syncColumns"
          :dataSource="syncTableList"
          :rowKey="(record, index) => index"
        ></a-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
import http from '@service/axios'
import api from '@service/api'
import { Modal } from 'ant-design-vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import pagination from '@component/pagination'
import SelectShop from '@component/selectShop'
import commitLazadaGoods from '@component/commitLazadaGoods'
import SelectMutiShop from '@component/selectMutiShop'
import categoryList from '@component/categoryList'

export default {
  name: 'platformGoods',
  components: {
    pagination,
    commitLazadaGoods,
    SelectShop,
    SelectMutiShop,
    categoryList,
  },
  watch: {
    platformList(val) {
      this.formData.platform = val[0]
      this.$nextTick(() => {
        this.queryPlatformSkuPageList(1)
      })
    },
  },
  mounted() {
    if (this.platformList.length) {
      this.formData.platform = this.platformList[0]
      this.$nextTick(() => {
        this.queryPlatformSkuPageList(1)
      })
    }
  },
  activated() {
    if (localStorage.getItem('refreshPlatformGoodsList')) {
      localStorage.removeItem('refreshPlatformGoodsList')
      this.queryPlatformSkuPageList(1)
    }
  },
  data() {
    return {
      bodyStyle: {
        width: '430px',
        maxHeight: '600px',
        overflowY: 'scroll',
      },
      platformPublishGoodsRequest: new Object(),
      shopListGroupByPlatform: new Object(),
      platformNameTab: '',
      publishVisible: true,
      tabGutter: 10,
      visible: false,
      formData: {
        nameOrSellerSku: undefined,
        categoryName: undefined,
        omsStatus: undefined,
        shopCode: undefined,
        platform: undefined,
      },
      pageNo: 1,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      total: 0,
      modalItem: {},
      copyVisible: false,
      confirmLoading: false,
      copyModalData: {},
      platformCode: '',
      syncStoreVisible: false,
      updateInventoryVisible: false,
      syncStoreCode: '',
      allShop: false,
      inventorySyncRecordVisible: false,
      tableLoading: false,
      syncTableList: [],
      syncColumns: [
        {
          dataIndex: 'updatedAt',
          title: this.$t('同步时间'),
        },
        {
          dataIndex: 'shopName',
          title: this.$t('店铺'),
        },
        {
          dataIndex: 'sellerCode',
          title: this.$t('商家编码'),
        },
        {
          dataIndex: 'barcode',
          title: this.$t('商品条码'),
        },
        {
          dataIndex: 'oldStock',
          title: this.$t('初始库存'),
        },
        {
          dataIndex: 'adjustStock',
          title: this.$t('差异库存'),
        },
        {
          dataIndex: 'newStock',
          title: this.$t('更新库存'),
        },
      ],
    }
  },
  provide() {
    return {
      page: 'platformGoodsList',
    }
  },
  computed: {
    ...mapState(['shopList']),
    ...mapGetters(['platformMapping']),
    tableColumns() {
      const vm = this
      return [
        {
          dataIndex: 'goodsName',
          title: this.$t('商品名称'),
          key: 'goodsName',
          fixed: 'left',
          width: 200,
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(record.itemId, this.tableData, 'itemId', index)
            return obj
          },
        },
        {
          title: this.$t('商家编码'),
          dataIndex: 'sellerSku',
          key: 'sellerSku',
          scopedSlots: { customRender: 'sellerSku' },
        },
        {
          title: this.$t('平台商家编码'),
          dataIndex: 'shopSku',
          key: 'shopSku',
        },
        {
          title: this.$t(`规格值`),
          dataIndex: 'styleValues',
          key: 'styleValues',
          width: 150,
        },
        {
          title: this.$t('平台'),
          key: 'zalChannel',
          dataIndex: 'zalChannel',
        },
        {
          title: this.$t(`店铺`),
          dataIndex: 'shopName',
          key: 'shopName',
        },

        {
          title: this.$t('创建时间'),
          key: 'createdAt',
          dataIndex: 'createdAt',
        },
        {
          title: this.$t('价格'),
          key: 'price',
          dataIndex: 'price',
        },
        {
          title: this.$t('售价'),
          key: 'specialPrice',
          dataIndex: 'specialPrice',
        },
        {
          title: this.$t('库存'),
          key: 'quantity',
          dataIndex: 'quantity',
          scopedSlots: { customRender: 'quantity' },
        },
        {
          title: this.$t('状态'),
          key: 'omsStatus',
          dataIndex: 'omsStatus',
          scopedSlots: { customRender: 'omsStatus' },
        },
        {
          title: this.$t('库存同步'),
          width: 120,
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
        {
          title: this.$t('操作'),
          key: 'action2',
          dataIndex: 'action',
          fixed: 'right',
          width: 130,
          customRender: (text, record, index) => {
            let btnContent = [
              vm.priv('f_platform_goods_detail')
                ? vm.$createElement('span', {
                    class: {
                      't-btn': true,
                    },
                    domProps: {
                      innerHTML: this.$t('查看详情'),
                    },
                    on: {
                      click() {
                        localStorage.setItem('detail_type', '')
                        localStorage.setItem('is_edit', '')
                        vm.goToDetail(record)
                      },
                    },
                  })
                : null,
              vm.priv('f_platform_goods_update')
                ? vm.$createElement('span', {
                    class: {
                      't-btn': true,
                    },
                    domProps: {
                      innerHTML: this.$t('编辑'),
                    },
                    on: {
                      click() {
                        localStorage.setItem('detail_type', true)
                        localStorage.setItem('is_edit', true)
                        vm.goToEdit(record)
                      },
                    },
                  })
                : null,
              vm.priv('f_platform_goods_replicate')
                ? vm.$createElement('span', {
                    class: {
                      't-btn': true,
                    },
                    domProps: {
                      innerHTML: this.$t('铺货'),
                    },
                    on: {
                      click() {
                        vm.copyPlatformGoods(record)
                      },
                    },
                  })
                : null,
            ]
            let auditBtn = [
              vm.$createElement('span', {
                class: {
                  't-btn': true,
                },
                domProps: {
                  innerHTML: this.$t('快速审核'),
                },
                on: {
                  click() {
                    vm.auditLazadaGoods(record)
                  },
                },
              }),
              vm.$createElement('span', {
                class: {
                  't-btn': true,
                },
                domProps: {
                  innerHTML: this.$t('审核'),
                },
                on: {
                  click() {
                    localStorage.setItem('detail_type', 'audit')
                    vm.goToDetail(record)
                  },
                },
              }),
            ]
            if (record.omsStatus === 'PUBLISH' || record.omsStatus === 'AUDIT_FAIL') {
              btnContent = [...btnContent]
            } else if (record.omsStatus === 'COMMITTED') {
              btnContent = [...btnContent, ...auditBtn]
            }
            var child = vm.$createElement(
              'div',
              {
                class: {
                  'flex-btns': true,
                },
              },
              btnContent
            )
            const obj = {
              children: child,
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(record['itemId'], this.tableData, 'itemId', index)
            return obj
          },
        },
      ]
    },
    statusListTop() {
      let list = [
        {
          statusName: this.$t(`全部`), //全部
          status: '',
        },
      ]
      for (let item in this.omsStatusMapping) {
        list.push({
          statusName: this.omsStatusMapping[item],
          status: item,
        })
      }
      return list
    },
    statusListBottom() {
      return [
        {
          statusName: this.$t(`超低价格`), //"超低价格"
          status: 'canCheck',
        },
        {
          statusName: this.$t(`图片缺失`), //"图片缺失"
          status: 'waitFinanceCheck',
        },
        {
          statusName: this.$t(`上架失败`), //"上架失败"
          status: 'pushFail',
        },
      ]
    },
    omsStatusMapping() {
      return {
        PUBLISH: this.$t(`待提交`),
        COMMITTED: this.$t(`已提交`),
        AUDIT_SUCCESS: this.$t(`审核成功`),
        AUDIT_FAIL: this.$t(`审核失败`),
        ON_SALE: this.$t(`已上架`),
        OFF_SALE: this.$t(`已下架`),
        SOLD_OUT: this.$t(`售罄`),
        DELETE: this.$t(`已删除`),
      }
    },
    platformList() {
      return Array.from(new Set(this.$store.state.nowPlatformList.map((item) => item.code)))
    },
    country() {
      return this.$store.getters.country
    },
    tabList() {
      return [
        {
          code: '',
          name: this.$t('全部商品'),
        },
        {
          code: 'ON_SALE',
          name: this.$t('已上架'),
        },
        {
          code: 'OFF_SALE',
          name: this.$t('已下架'),
        },
        {
          code: 'MIS_MATCH',
          name: this.$t('不匹配'),
          tips: this.$t('不匹配解释'),
        },
        {
          code: 'SOLD_OUT',
          name: this.$t('已售罄'),
        },
        {
          code: 'DELETE',
          name: this.$t('已删除'),
        },
      ]
    },
    tableHeight() {
      return (window.innerHeight - 450) * this.$store.state.rate
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      for (let item in this.formData) {
        this.formData[item] = undefined
      }
      this.pageNo = 1
      this.pageSize = 10
      this.formData.platform = this.platformList[0]
      setTimeout(() => {
        this.queryPlatformSkuPageList(1)
      }, 100)
    },
    onSwitchChange(syncStock, skuIds, platform) {
      let url = syncStock ? api.openSyncStock : api.closeSyncStock
      http({
        instance: this,
        type: 'post',
        url: url,
        data: {
          skuIds,
          platform,
        },
        success: () => {
          this.$success(this.$t('已开启同步'))
          this.$nextTick(() => {
            this.queryPlatformSkuPageList(1)
          })
        },
      })
    },
    changeTabs(key) {
      this.formData.platform = key
      this.formData.shopCode = undefined
      this.totalCount = undefined
      setTimeout(() => {
        this.queryPlatformSkuPageList(1)
      }, 100)
    },
    handleCategoryChange(data) {
      if (data) {
        this.formData.categoryId = data.categoryId
        this.formData.categoryName = data.categoryName
      } else {
        this.formData.categoryId = ''
        this.formData.categoryName = ''
      }
    },
    selectedShop(data) {
      this.formData.shopCode = data.code
    },
    selectedSyncShop(data) {
      this.syncStoreCode = data.code
    },
    platformGoodsSync() {
      http({
        instance: this,
        type: 'post',
        url: api.platformGoodsSync,
        data: {
          isSyncOmsGoods: true,
        },
        success: () => {
          this.$success(this.$t('已开启同步'))
        },
      })
    },
    queryPlatformSkuPageList(first_page = false) {
      if (!this.formData.shopCode) {
        let item = this.shopList.find((elem) => elem.platform === this.formData.platform)
        this.formData.shopCode = item.shopCode
      }
      this.selectedRowKeys = []
      first_page && (this.pageNo = 1)
      this.$nextTick(() => {
        http({
          instance: this,
          type: 'post',
          hasLoading: true,
          url: api.queryPlatformSkuPageList,
          data: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            shopCode: this.formData.shopCode,
            omsStatus: this.formData.omsStatus,
            categoryName: this.formData.categoryName,
            nameOrSellerSku: this.formData.nameOrSellerSku,
            platform: this.formData.platform,
          },
          success: (res) => {
            let platformSkuList = res.result?.platformSkuList
            if (!platformSkuList) {
              this.tableData = []
              return
            }
            localStorage.setItem(
              'shopCode',
              platformSkuList.length > 0 ? platformSkuList[0].shopCode : ''
            )
            this.tableData = platformSkuList
            this.total = res.result.total
            if (platformSkuList.length === 0) {
              // this.$warning("No Data");
            }
          },
        })
      })
    },
    onExport() {
      let url =
        api.exportLazadaSku +
        `?nameOrSellerSku=${this.formData.nameOrSellerSku}&categoryName=${this.formData.categoryName}&omsStatus=${this.formData.omsStatus}&brand=`
      window.open(url)
    },
    goToDetail(record) {
      let obj = {
        view: 'platformGoodsDetail',
        name: 'platformGoodsDetail',
        query: { id: record.itemId, shopCode: record.shopCode, platform: this.formData.platform },
      }
      this.addPane(obj)
      this.$router.push(obj)
    },
    goToEdit(record) {
      let obj = {
        view: 'platformGoodsDetail',
        name: 'platformGoodsDetail',
        query: { id: record.itemId, shopCode: record.shopCode },
      }
      this.addPane(obj)
      this.$router.push(obj)
    },
    setSearchStatus(status) {
      this.$set(this.formData, 'omsStatus', status)
      this.queryPlatformSkuPageList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows
      this.selectedRowKeys = selectedRowKeys
    },
    batchDelete() {
      this.batchHandle({
        modalAction: this.$t('删除'),
        url: api.batchRemoveLazadaSku,
      })
    },
    batchOn() {
      this.batchHandle({
        modalAction: this.$t('上架商品'),
        url: api.onSalePlatformGoods,
        data: {
          skuIdList: [...this.selectedRowKeys],
          shopCode: this.formData.shopCode,
        },
      })
    },
    batchOff() {
      this.batchHandle({
        modalAction: this.$t('下架商品'),
        url: api.offSalePlatformGoods,
        data: {
          skuIdList: [...this.selectedRowKeys],
          shopCode: this.formData.shopCode,
        },
      })
    },
    batchOpenSyncStock() {
      this.batchHandle({
        modalAction: this.$t('批量开启同步'),
        url: api.openSyncStock,
        data: {
          skuIds: [...this.selectedRowKeys],
          platform: this.formData.platform,
        },
      })
    },
    batchCloseSyncStock() {
      this.batchHandle({
        modalAction: this.$t('批量关闭同步'),
        url: api.closeSyncStock,
        data: {
          skuIds: [...this.selectedRowKeys],
          platform: this.formData.platform,
        },
      })
    },
    async batchCopyPlatformGoods() {
      let replicatePublishRequestList = this.selectedRows.map((item) => {
        return {
          itemId: item.itemId,
          srcShopCode: item.shopCode,
          shopCode: item.shopCode,
          destShopCodeList: this.copyModalData.destShopCodeList,
        }
      })
      await this.batchHandle({
        modalAction: this.$t('批量铺货'),
        url: api.batchReplicatePublish,
        data: {
          replicatePublishRequestList,
        },
      })
      this.closeGoodsPublish()
    },
    batchHandle({ modalAction, url, data }) {
      if (this.confirmSelect()) return
      Modal.confirm({
        title: 'Confirm',
        content: `${this.$t('确认')}${modalAction}${this.$t('选中商品')}`,
        okText: this.$t('确认'),
        cancelText: this.$t('取消'),
        onOk: () => {
          http({
            instance: this,
            type: 'post',
            hasLoading: true,
            url,
            data: data,
            success: () => {
              this.$success(`${modalAction}${this.$t('已完成')}`)
              this.queryPlatformSkuPageList()
            },
          })
        },
      })
    },
    confirmSelect() {
      let is_empty = false
      if (this.selectedRowKeys.length === 0) {
        is_empty = true
        this.$warning(this.$t('至少选择一项'))
      }
      return is_empty
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryPlatformSkuPageList()
    },
    auditLazadaGoods(item) {
      let itemIdList = [item.itemId]
      Modal.confirm({
        title: this.$t('审核'),
        content: this.$t('该商品审核结果是'),
        okText: this.$t('审核成功'),
        cancelText: this.$t('审核失败'),
        onOk: () => {
          http({
            instance: this,
            type: 'post',
            hasLoading: true,
            url: api.auditLazadaGoods,
            data: {
              itemIdList,
              success: true,
            },
            success: () => {
              this.$success(this.$t('操作成功'))
              item.omsStatus = 'AUDIT_SUCCESS'
            },
          })
        },
        onCancel: () => {
          http({
            instance: this,
            type: 'post',
            hasLoading: true,
            url: api.auditLazadaGoods,
            data: {
              itemIdList,
              success: false,
            },
            success: () => {
              this.$success(this.$t('操作成功'))
              item.omsStatus = 'AUDIT_FAIL'
            },
          })
        },
      })
    },
    /**
     * 单元格合并处理
     * @param text 当前单元格的值
     * @param data 当前分页所有数据
     * @param key 当前列的dataIndex
     * @param index 当前数据所在下标
     * @returns {number} 待合并单元格数量
     */
    mergeCells(text, data, key, index) {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      return rowSpan
    },
    handleOk() {
      this.tableData.forEach((item) => {
        if (item.itemId === this.modalItem.itemId) {
          item.omsStatus = 'COMMITTED'
        }
      })
    },
    copyPlatformGoods(record) {
      this.copyVisible = true
      this.allShop = false
      this.copyModalData = record
      this.$set(this.copyModalData, 'destShopCodeList', [])
    },
    openCopyPlatformGoods() {
      this.copyVisible = true
      this.allShop = true
    },
    openStoreModal() {
      this.updateInventoryVisible = true
    },
    changePlatformNameTab(value) {
      this.platformNameTab = value
    },
    setGoodsPublish() {
      if (!this.allShop) {
        http({
          instance: this,
          type: 'post',
          url: api.replicatePublish,
          data: {
            itemId: this.copyModalData.itemId,
            srcShopCode: this.copyModalData.shopCode,
            destShopCodeList: this.copyModalData.destShopCodeList,
          },
          success: (res) => {
            if (res.result.errorShopNameList.length) {
              let msg = this.$t('铺货') + this.$t('失败') + ':'
              res.result.errorShopNameList.forEach((item) => {
                msg += '\n' + item
              })
              this.$warning(msg)
            } else {
              this.$success(this.$t('已完成'))
            }
            this.closeGoodsPublish()
          },
          complete: () => {},
        })
      } else {
        this.batchCopyPlatformGoods()
      }
    },
    closeGoodsPublish() {
      this.copyVisible = false
      this.copyModalData = {}
    },
    publishGoods(value) {
      if (!value) {
        return
      }
      this.platformPublishGoodsRequest[this.platformNameTab] = value
    },
    // 快速新建商品
    goToAddProduct(quickCreate) {
      let obj = {
        name: 'productsDetail',
        view: 'productsDetail',
        query: { type: 'add', quickCreate },
      }
      localStorage.setItem('from_create', 1)
      this.addPane(obj)
      this.$router.push(obj)
    },
    goToSyncStore() {
      const merchantId = JSON.parse(localStorage.getItem('userInfo'))?.merchantId
      http({
        instance: this,
        type: 'post',
        hasLoading: true,
        url: api.goodsSyncSkuInventory,
        data: {
          shopCode: this.syncStoreCode,
          merchantId: merchantId,
        },
        success: () => {
          this.$success(this.$t('已开启同步'))
        },
      })
    },
    async showSyncList(record) {
      this.inventorySyncRecordVisible = true
      this.tableLoading = true
      let res = await http({
        url: api.queryInventorySyncRecordList,
        data: {
          page_no: 1,
          page_size: 9999,
          sellerCode: record.sellerSku,
          platform: record.platform,
          shopCode: record.shopCode,
        },
      })
      this.syncTableList = res.result.syncStockLogList
      this.tableLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.action-list {
  display: flex;
}
.flex-btns {
  max-width: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .t-btn {
    cursor: pointer;
    margin-right: 10px;
    color: $primary-color;
    white-space: nowrap;
  }
}
.text-info {
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    padding: 10px;
  }
}
.bgcBtn {
  color: $primary-color;
  border-color: $primary-color;
  margin-right: 12px;
}
.ant-modal-confirm .ant-modal-body {
  white-space: pre-wrap;
}
</style>

<style lang="scss">
.resize-table-th {
  position: relative;
  .table-draggable-handle {
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
  }
}
</style>
