<template>
  <div class="category-tree">
    <div v-if="defaultName && (!can_edit || disabled) && !show">
      <span>{{ defaultName }}</span>
      <span v-if="!disabled" class="text-info" @click="can_edit = true">
        {{ $t('修改') }}
      </span>
    </div>
    <div v-show="(can_edit && !disabled) || show">
      <a-cascader
        :options="options"
        :fieldNames="{
          label: 'name',
          value: 'categoryId',
          children: 'children',
        }"
        :load-data="queryPlatformCategoryTree"
        :placeholder="$t('请选择')"
        change-on-select
        @change="onChange"
        :disabled="disabled"
        style="width: 100%"
      />
      <!-- v-model="cascaderValue" -->
      <!-- :show-search="{ filter }" -->
      <!-- @popupVisibleChange="popupVisibleChange" -->
      <span v-if="!disabled && !show" class="text-info" @click="can_edit = false">
        {{ $t('取消') }}
      </span>
    </div>
  </div>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'
import { Cascader } from 'ant-design-vue'
export default {
  //lazada分类树
  name: 'categoryTree',
  props: {
    defaultName: {
      type: [String, Number],
    },
    defaultValue: {
      type: Array,
      default: () => {
        return []
      },
    },
    show: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'LAZADA',
    },
    country: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      can_edit: false,
      cascaderValue: [],
      parentId: '',
    }
  },
  components: {
    'a-cascader': Cascader,
  },
  mounted() {
    this.queryPlatformCategoryTree('parent')
    if (this.defaultValue) {
      this.$nextTick(() => {
        this.cascaderValue = this.defaultValue
      })
    }
  },
  methods: {
    filter(inputValue, path) {
      return path.some((option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    clear() {
      this.cascaderValue = []
    },
    queryPlatformCategoryTree(value) {
      let targetOption = []
      if (value == 'parent') {
        this.parentId = 0
      } else {
        targetOption = value[value.length - 1]
        targetOption.loading = true
        this.parentId = value[value.length - 1].value
      }

      // load options lazily
      setTimeout(() => {}, 1000)
      let data = {
        platformList: [this.type],
        parentId: this.parentId,
      }
      if (this.country) {
        data.country = this.country
      }
      http({
        instance: this,
        type: 'post',
        url: api.queryPlatformCategoryTree,
        hasLoading: true,
        data: data,
        success: (res) => {
          res.result.platformCategoryTree[this.type].forEach((item) => {
            item.isLeaf = false
            item.label = item.name
            item.value = item.categoryId
            delete item.children
          })
          if (value == 'parent') {
            this.parentId = 0
            let list = res.result.platformCategoryTree
            this.options = list[this.type]
          } else {
            targetOption.loading = false
            this.getId(this.options, this.parentId, res.result.platformCategoryTree[this.type])
            this.options = [...this.options]
          }
        },
      })
    },
    getId(arr, id, res) {
      arr.forEach((item) => {
        if (item.categoryId == id) {
          if (res.length) {
            item.children = res
          } else {
            item.isLeaf = true
            delete item.children
          }
        } else {
          if (item.children && item.children.length > 0) {
            this.getId(item.children, id, res)
          }
        }
      })
    },
    onChange(value, selectedOptions) {
      this.cascaderValue = value
      let text = selectedOptions ? selectedOptions.map((o) => o.name).join(', ') : ''
      this.$emit('changeCategory', { text, value })
    },
    popupVisibleChange(value) {
      this.$nextTick(() => {
        this.$emit('closeTree', !value)
      })
    },
  },
}
</script>

<style scoped>
.text-info {
  cursor: pointer;
  margin-left: 10px;
}
</style>
