<template>
  <a-select
    mode="multiple"
    :placeholder="placeholder || $t('请选择店铺')"
    style="width: 100%"
    v-model="selectShops"
    :disabled="disabled"
    :showArrow="true"
    @change="changeHandle"
    @focus="getShopList"
  >
    <a-select-option v-for="item in shop_list" :key="item.code" :value="item.code" :option="item">
      {{ item.platformLocale }}-{{ item.name }}
    </a-select-option>
  </a-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['placeholder', 'disabled', 'allShop', 'shopCodeList'],
  data() {
    return {
      selectShops: [],
      dataType: 'string',
      shop_list: [],
    }
  },
  mounted() {
    this.getShopList()
  },
  computed: {
    ...mapGetters(['currentShop', 'partyId']),
  },
  methods: {
    ...mapActions(['getShopInfoList']),
    getShopList() {
      if (this.shop_list.length) return
      this.getShopInfoList((list) => {
        if (!this.allShop) {
          list = list.filter((item) => item.partyId == this.partyId)
        }
        this.shop_list = list
      })
    },
    changeHandle(value, option) {
      let selectShopList = option.map((item) => item.data.attrs.option)
      let shopCodes = option.map((item) => item.data.attrs.option.code)
      this.$emit('update:shopCodeList', shopCodes)
      this.$emit('change', selectShopList)
    },
  },
}
</script>
